<template>
    <section class="mb-10 solutions-holder">   
            <v-layout justify-space-around justify-center>
                <v-flex xs10 sm10 offset-sm1 class="mt-10">
                        <v-card flat tile color="" width="100%" class="transparent">
                             <v-card-title primary-title class="justify-center mb-6 px-0">
                                    <h3 v-if="!is_screen_small" class="zamu-heading transact-heading  slogan-heading mb-3">Contact our sales team </h3><br />
                                    <h3 v-if="is_screen_small" class="zamu-heading transact-heading  slogan-heading mb-3">Contact our  <br />sales team</h3><br />

                                    <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center transact-underline ">
                                        <v-card-text class="transact-divider divider-purple"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-card-text align="center" justify="center">
                                We’d love to hear more about your business, and how we can best serve you
                            </v-card-text>
                      </v-card>
                        <v-card tile flat color=" " class="mt-10">
                            <v-card-title class="px-0 zamu-heading form-header mb-10"></v-card-title>
                            <v-card-text>
                                <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                                        {{ notification }}
                                    </v-alert>
                                <v-form  ref="form" v-model="valid" lazy-validation >
                                    <v-row no-gutters class="ma-0">
                                        <v-col cols="12" lg="5" md="5" sm="12" xs="12" class="mr-13">
                                            <label for="">Full Name</label>
                                            <v-text-field
                                            v-model="name"
                                            :rules="nameRules"
                                            required
                                            outlined
                                            dense
                                            ></v-text-field>
                                        </v-col>
                                           <v-col cols="12" lg="5" md="5" sm="12" xs="12" class="mr-13">
                                            <label for="">Phone Number</label>
                                            <v-text-field
                                            v-model="phone_no"
                                            :rules="phoneRules"
                                            required
                                            outlined
                                            dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="5" md="5" sm="12" xs="12" class="mr-13">
                                            <label for="">Company Name</label>
                                            <v-text-field
                                            v-model="company_name"
                                             :rules="[v => !!v || 'Company Name is required']"
                                            required
                                            outlined
                                            dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="5" md="5" sm="12" xs="12" >
                                            <label for="">Company E-mail</label>
                                            <v-text-field
                                            v-model="email"
                                            :rules="emailRules"
                                            required
                                            outlined
                                            dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="5" md="5" sm="12" xs="12" class="mr-13">
                                            <label for="">Select Country</label>
                                            <v-select
                                            v-model="country"
                                            :items="countriesData"
                                            :rules="[v => !!v || 'Country is required']"
                                            item-text="name"
                                            item-value="id"
                                            required
                                            outlined
                                            dense
                                            ></v-select>
                                        </v-col>
                                          <v-col cols="12" lg="5" md="5" sm="12" xs="12" >
                                            <label for="">Payment Volume </label>
                                             <v-select
                                            v-model="range"
                                            :items="paymentVolumes"
                                            :rules="[v => !!v || 'Payment Volumes is required']"
                                            item-text="range"
                                            item-value="id"
                                            required
                                            outlined
                                            dense
                                            ></v-select>
                                            
                                        </v-col>
                                        <v-col cols="12" lg="11" md="11" sm="12" xs="12" >
                                            <label for="">Your Message</label>
                                            <v-textarea
                                            v-model="message"
                                            outlined
                                            :rules="messageRules"
                                            required
                                            ></v-textarea>
                                        </v-col>
                                        
                                            <v-btn
                                            :disabled="!valid || loading"
                                            :color="`${zamuYellow3}`"
                                            class="mr-4 zamu-sub-heading "
                                            large
                                            @click="validate"
                                            >
                                            Send Message
                                            <v-progress-circular
                                                indeterminate
                                                color="primary"
                                                class="ml-4"
                                                :size="20"
                                                v-if="loading"
                                                ></v-progress-circular>
                                            </v-btn>

                                    </v-row>

                                </v-form>
                            </v-card-text>

                        </v-card>
                </v-flex>
            </v-layout>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';

export default {
    name: 'SalesForm',
    mixins: [colorMixin, coreMixin],
        data () {
        return {
            valid: false,
            name: '',
            email: '',
            select: null,
            phone_no: '',
            company_name: '',
            message: '',
            checkbox: false,
            loading: false,
            notification: '',
            actionClass: '',
            items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
            country: 1,
            range: 3,
            countries: null,
            volumes: null,
    };
    },
      computed: {
        countriesData() {
            return !this.countries ? [] : this.countries;
        },
         paymentVolumes() {
            return !this.volumes ? [] : this.volumes;
        },
    },
    async mounted () {
        const countries = await this.retrieveActiveCountries();
        this.countries = countries;
        const volumes = await this.retrieveActivePaymentVolumes();
        this.volumes = volumes;

    },

    methods: {
     async validate () {
        const formvalid = this.$refs.form.validate();
        if(!formvalid) {
            return;
        }
               this.loading = true;

               const payload = {
                    name: this.name,
                    email: this.email,
                    company_name: this.company_name,
                    phone_no: this.phone_no,
                    country: this.country,
                    volume: this.range,
                    message: this.message
                };
                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint: 'sales-contact/new',
                };
                  try {
                    const res = await this.axiosPostRequest(fullPayload);
                    this.loading = false;
                    this.notification = res.status === 200 ? 'Message saved successful' : res.data.message;
                    this.actionClass = res.status === 200 ? 'success' : 'error';

                    if(res.status === 200 ) {
                        this.reset()
                        this.$router.push('/');
                    }

                } catch (error) {
                    this.notification = 'Message failed to save. Contact the admin';
                    this.actionClass = 'error';
                    this.loading = false;
                    return error.response;
                }

      },
      reset () {
        this.$refs.form.reset()
      },
    },

}
</script>

<style>
.v-list-item__content {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87) !important;
}
.slogan-heading{
    font-size: 40px !important;
}

</style>